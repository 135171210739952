body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Margin */

.mt-26 {
  margin-top: 26px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-26 {
  margin-bottom: 26px;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

/* Padding */

.pl-32 {
  padding-left: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pad-0{
  padding: 0px;
}

/* Border */

.br-6 {
  border-radius: 6px !important;
}

.br-0 {
  border-radius: 0 !important;
}

.align-flex-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
