.sideBar {
  background-color: var(--sidebar-background-color);
  width: 225px;
  height: 100vh;
}

.sideBarLogo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-bottom: 44px;
}

.sideBarMenu {
  padding: 0 13px;
  max-height: calc(100vh - 124px);
  overflow: auto;
  background-color: var(--sidebar-background-color);
}

.MuiLink-root img {
  width: 218px;
  height: 75px;
}

.sideBarMenu li a.collapsed,
.sideBarMenu li a,
.sideBarMenu li button.collapsed,
.sideBarMenu li button {
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
  text-align: left;
  width: 100%;
  color: var(--sidebar-text-color);
  transition: .3s;
  background-color: transparent;
}

/* .sideBarMenu li a {
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
  text-align: left;
  width: 100%;
  color: var(--theme-color);
  transition: .3s;
  background-color: #fff;
} */

.sideBarMenu ul li ul li a {
  padding-left: 30px !important;
}

.sideBarMenu li a:hover,
.sideBarMenu li button:hover {
  color: var(--theme-color) !important;
  transition: .3s;
  background-color: var(--sidebar-navigation-color);
}

.sideBarMenu li button.collapsed:hover,
.sideBarMenu li a.collapsed:hover {
  transition: .3s;
  color: var(--sidebar-navigation-color);
}

.sideBarMenu li button:hover,
.sideBarMenu li a:hover {
  transition: .3s;
  color: var(--sidebar-navigation-color);
}

.sideBarMenu li a.active,
.sideBarMenu li button.active {
  background-color: var(--sidebar-navigation-color);
  color: var(--theme-color);
}

.sideBarMenu li .collapse.show {
  background: var(--sidebar-sub-navigation-color);
  border-radius: 4px;
}

.sideBarMenu li .collapsing {
  background: var(--sidebar-navigation-color);
  border-radius: 4px;
}

/* .sideBarMenu li .collapse.show ul li a,
.sideBarMenu li .collapsing ul li a {
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
  text-align: left;
  width: 100%;
  color: var(--theme-color);
  transition: .3s;
  background-color: #fff;
} */

.sideBarMenu li .collapse.show ul li a:hover {
  color: var(--sidebar-text-color);
  transition: .3s;
}

.sideBarMenu ul li.mb-1 {
  margin-bottom: 40px !important;
}

/* width */
.sideBarMenu::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.sideBarMenu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--sidebar-box-shadow-color);
  border-radius: 0px;
}

/* Handle */
.sideBarMenu::-webkit-scrollbar-thumb {
  background: var(--sidebar-text-color);
  border-radius: 0px;
}

/* Handle on hover */
.sideBarMenu::-webkit-scrollbar-thumb:hover {
  background: var(--sidebar-hover-color);
}